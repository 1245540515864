@import '../../config';

.dropdown {
  position: absolute;
  z-index: 2000;
  background: white;
  border-radius: 5px;
  padding: 15px;
  width: 200px;
  box-shadow: $main-shadow;
}

.dropdown__row {
  margin-top: 10px;
}