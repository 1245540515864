@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif; }

.range__track {
  fill: lightgrey; }

.range__handle {
  fill: #5E6062;
  cursor: pointer; }

.sidebar {
  background: #eeeeee;
  display: flex;
  width: 320px;
  flex-direction: column;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
  flex-shrink: 0; }

.sidebar__search-row {
  width: 100%;
  background: white;
  display: flex;
  height: 60px;
  align-items: center;
  color: #5E6062;
  font-size: 15px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45); }

.sidebar__search-row--search {
  justify-content: space-between; }

.sidebar__search-row--return {
  cursor: pointer; }

.sidebar__search-row-left,
.sidebar__search-row-right {
  display: flex;
  height: 100%;
  align-items: center; }

.sidebar__search-row-left {
  padding-left: 20px; }

.sidebar__search-row-right {
  margin-right: 20px; }

.sidebar__search-icon {
  margin-right: 10px; }

.sidebar__search-icon--return {
  font-size: 30px;
  cursor: pointer; }

.sidebar__text-input {
  border: none;
  height: 95%; }

.sidebar__text-input:focus {
  outline: none; }

.sidebar__search-row-divider {
  height: 26px;
  background: #cccccc;
  width: 1px;
  margin-right: 10px;
  margin-left: 10px; }

.sidebar__search-back-icon {
  font-size: 30px;
  margin-left: 20px;
  margin-right: 6px; }

.sidebar__search-back-text {
  font-style: italic; }

.sidebar__inner {
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
  height: calc(100vh - 169px); }

.sidebar__legend {
  margin-bottom: 10px; }

.sidebar__block {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
  margin-top: 10px; }

.sidebar__block-outer {
  margin-top: 20px; }

.sidebar__block-title-row {
  display: flex;
  justify-content: space-between; }

.sidebar__block-title-row-left {
  display: flex; }

.sidebar__block-title-icon {
  margin-right: 8px; }

.sidebar__block-toggle-button,
.sidebar__search-area-button {
  cursor: pointer; }

.sidebar__search-area-button {
  width: 20px;
  height: 20px; }

.sidebar__area-icon {
  fill: #5E6062; }

.sidebar__layers-block,
.sidebar__search-layer-group {
  margin-top: 20px; }

.sidebar__layers-block--first,
.sidebar__search-layer-group--first {
  margin-top: 0px; }

.sidebar__layers-title-left,
.sidebar__layers-title-right,
.sidebar__layers-title-row,
.sidebar__search-layer-title-row {
  text-transform: capitalize;
  display: flex; }

.sidebar__layer-swatch {
  display: block;
  width: 38px;
  height: 31px;
  border-radius: 4px; }

.sidebar__layers-title-left {
  font-size: 13px; }

.sidebar__layers-title-right {
  color: #5E6062;
  font-size: 24px;
  cursor: pointer; }

.sidebar__layers-title-row,
.sidebar__search-layer-title-row {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; }

.sidebar__layers-title {
  margin-left: 8px; }

.sidebar__layer-row,
.sidebar__search-feature-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px; }

.sidebar__layer-button,
.sidebar__search-feature-button {
  min-height: 20px;
  width: 180px;
  display: flex;
  align-items: center;
  background: #eeeeee;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer; }

.sidebar__layer-button--highlighted,
.sidebar__layer-button:hover,
.sidebar__search-feature-button:hover,
.sidebar__search-feature-button--highlighted {
  background: #dddddd; }

.sidebar__layer-button-inner,
.sidebar__search-feature-button-text {
  padding-left: 10px;
  padding-right: 10px; }

.sidebar__choropleth-layer-row {
  margin-top: 10px; }

.sidebar__choropleth-slider-labels {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px; }

.sidebar__toggle-button {
  cursor: pointer; }

.sidebar__filmstrip {
  display: flex;
  flex-wrap: wrap; }

.sidebar__view-thumb {
  width: 70px;
  height: 70px;
  background: lightgrey;
  border-radius: 35px;
  margin-right: 7px;
  margin-bottom: 8px;
  cursor: pointer;
  background-size: cover; }

.sidebar__view-thumb:nth-child(3n) {
  margin-right: 0px; }

.sidebar__overlay-thumb {
  width: 70px;
  height: 70px;
  background: lightgrey;
  border-radius: 5px;
  margin-right: 7px;
  margin-bottom: 8px;
  cursor: pointer;
  background-size: cover; }

.sidebar__overlay-thumb:nth-child(3n) {
  margin-right: 0px; }

.sidebar__choropleth-slider {
  height: 25px; }

.timeline__slider-svg path {
  opacity: 0; }

.raster-probe {
  z-index: 2;
  width: 330px;
  background: white;
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45); }

.raster-probe__inner {
  padding: 20px; }

.raster-probe__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 13px; }

.raster-probe__title {
  font-weight: bold;
  padding-right: 10px; }

.raster-probe__close-icon {
  cursor: pointer;
  color: #5E6062; }

.raster-probe__close-icon:hover {
  color: black; }

.raster-probe__image {
  width: 100%;
  height: auto;
  background: lightgrey;
  cursor: pointer; }

.raster-probe__caption {
  font-size: 14px;
  margin-top: 10px;
  color: #666;
  margin-bottom: 10px;
  font-size: 13px; }

.raster-probe__nav-buttons {
  display: flex;
  justify-content: space-between; }

.raster-probe__footer-button {
  height: 36px;
  background: #EEEEEE;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  color: #5E6062; }

.raster-probe__footer-button:hover {
  background: #e6e6e6; }

.raster-probe__prev {
  margin-right: 10px; }

.raster-probe__next {
  margin-left: 10px; }

input[type='range'] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%; }

input[type='range']:focus {
  outline: none; }

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 16px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #eeeeee;
  border-radius: 6px;
  border: 0px solid #000000; }

input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 1px #000000;
  border: 0px solid #000000;
  height: 24px;
  width: 13px;
  border-radius: 3px;
  background: #5e6062;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -4px; }

input[type='range']:focus::-webkit-slider-runnable-track {
  background: #eeeeee; }

input[type='range']::-moz-range-track {
  width: 100%;
  height: 16px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #eeeeee;
  border-radius: 6px;
  border: 0px solid #000000; }

input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 1px #000000;
  border: 0px solid #000000;
  height: 24px;
  width: 13px;
  border-radius: 3px;
  background: #5e6062;
  cursor: pointer; }

input[type='range']::-ms-track {
  width: 100%;
  height: 16px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent; }

input[type='range']::-ms-fill-lower {
  background: #eeeeee;
  border: 0px solid #000000;
  border-radius: 12px;
  box-shadow: 0px 0px 0px #000000; }

input[type='range']::-ms-fill-upper {
  background: #eeeeee;
  border: 0px solid #000000;
  border-radius: 12px;
  box-shadow: 0px 0px 0px #000000; }

input[type='range']::-ms-thumb {
  box-shadow: 0px 0px 1px #000000;
  border: 0px solid #000000;
  height: 24px;
  width: 13px;
  border-radius: 3px;
  background: #5e6062;
  cursor: pointer; }

input[type='range']:focus::-ms-fill-lower {
  background: #eeeeee; }

input[type='range']:focus::-ms-fill-upper {
  background: #eeeeee; }

.atlas {
  width: 100%;
  height: 100%; }

.atlas--area-searching canvas {
  cursor: crosshair; }

.atlas__sidebar-button {
  position: absolute;
  left: 10px;
  top: 120px;
  width: 30px;
  height: 60px;
  background: white;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.tooltip {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  pointer-events: none;
  border: 1px solid #999999;
  border-radius: 5px;
  z-index: 2000;
  margin: 15px;
  padding: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center; }

.dropdown {
  position: absolute;
  z-index: 2000;
  background: white;
  border-radius: 5px;
  padding: 15px;
  width: 200px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45); }

.dropdown__row {
  margin-top: 10px; }

.header {
  width: 100%;
  height: 108px;
  background: white;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
  z-index: 500; }

.header__inner {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column; }

.header__top {
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0; }

.header__top-title {
  color: #002469;
  font-weight: bold; }

.header__top-right {
  display: flex;
  margin-right: 10px; }

.header__top-button {
  width: 25px;
  height: 25px;
  background: #EEEEEE;
  margin-left: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5E6062;
  cursor: pointer;
  font-size: 14px; }

.header__top-button:hover {
  background: #e6e6e6; }

.header__timeline-row {
  align-items: center;
  flex-grow: 1;
  padding-left: 20px;
  padding-right: 10px;
  display: flex; }

.header__stepper {
  width: 230px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0; }

.header__stepper-button {
  width: 36px;
  height: 36px;
  background: #EEEEEE;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 15px;
  color: #5E6062;
  cursor: pointer; }

.header__stepper-button:hover {
  background: #e6e6e6; }

.header__stepper-year {
  font-size: 30px;
  font-weight: bold; }

.header__timeline {
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 2;
  overflow: hidden; }

.timeline__slider {
  height: 40px;
  flex-grow: 1;
  margin-left: 30px; }

.timeline__track {
  fill: lightgrey;
  cursor: pointer; }

.timeline__handle {
  fill: #5E6062;
  cursor: pointer;
  pointer-events: none; }

.timeline__handle-line {
  pointer-events: none; }

.timeline__slider-svg {
  background-color: lightgrey;
  border-radius: 5px; }
  .timeline__slider-svg path {
    opacity: 0; }
  .timeline__slider-svg .tick {
    pointer-events: none; }
    .timeline__slider-svg .tick line {
      stroke: #999999; }
    .timeline__slider-svg .tick text {
      font-size: 13px; }

.timeline__axis {
  pointer-events: none; }

.header--mobile .header__inner {
  flex-direction: column; }

.header--mobile .header__stepper {
  height: 50px;
  width: 100%;
  justify-content: center; }

.header--mobile .header__stepper-year {
  margin-left: 25px;
  margin-right: 25px; }

.header--mobile .header__stepper-button {
  width: 27px;
  height: 27px; }

.lightbox {
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 3000;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center; }

.lightbox__container {
  width: 1111px;
  max-width: 95%;
  max-height: 85%;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
  background: rgba(255, 255, 255, 0.9); }

.lightbox__inner {
  padding: 20px; }

.lightbox__img {
  width: 100%;
  height: auto; }

.lightbox__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: 13px/17px Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #666; }

.lightbox__footer-imgName {
  font-weight: bold; }

.lightbox__footer-imgCredit {
  font-style: italic; }

.lightbox__footer-button {
  background-color: #eee;
  color: #666;
  border-radius: 5px;
  margin: auto;
  text-align: center;
  text-decoration: none;
  width: 160px;
  padding: 5px;
  float: right;
  cursor: pointer;
  font-size: 14px; }

.lightbox__footer-button:hover {
  background-color: #ccc;
  color: #333; }

.mobile-menu {
  position: absolute;
  right: 20px;
  bottom: 20px; }

.area-box {
  border: 2px solid #141414;
  background: rgba(60, 60, 60, 0.2);
  pointer-events: none; }

.loader {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  font-size: 30px;
  z-index: 3000; }

.app {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; }

.app__body {
  display: flex;
  width: 100%;
  height: 100%; }

.app__atlas-outer {
  width: 100%;
  position: relative; }

