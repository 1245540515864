@import '../../config';

.lightbox {
  width: 100%;
  // height: 100vh;
  background: rgba(255, 255, 255, 0.7);
  z-index: 3000;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lightbox__container {
  width: 1111px;
  max-width: 95%;
  max-height: 85%;
  border-radius: 5px;
  box-shadow: $main-shadow;
  background: rgba(255, 255, 255, 0.9);
}

.lightbox__inner {
  padding: 20px;
}

.lightbox__img {
  width: 100%;
  height: auto;
}

.lightbox__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: 13px/17px Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #666;
}
.lightbox__footer-imgName {
  font-weight: bold;
}
.lightbox__footer-imgCredit {
  font-style: italic;
}
.lightbox__footer-button {
  background-color: #eee;
  color: #666;
  border-radius: 5px;
  margin: auto;
  text-align: center;
  text-decoration: none;
  width: 160px;
  padding: 5px;
  float: right;
  cursor: pointer;
  font-size: 14px;
}

.lightbox__footer-button:hover {
  background-color: #ccc;
  color: #333;
}
