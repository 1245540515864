.app {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app__body {
  display: flex;
  width: 100%;
  height: 100%; 
}

.app__atlas-outer {
  width: 100%;
  position: relative;
}