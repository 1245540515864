@import '../../config';

.atlas {
  width: 100%;
  height: 100%;
}

.atlas--area-searching canvas {
  cursor: crosshair;
}

.atlas__sidebar-button {
  position: absolute;
  left: 10px;
  top: 120px;
  width: 30px;
  height: 60px;
  background: white;
  box-shadow: $main-shadow;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
