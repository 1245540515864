@import '../../config';

$stepper-width: 230px;
$stepper-button-size: 36px;
$header-padding: 20px;

.header {
  width: 100%;
  height: 108px;
  background: white;
  box-shadow: $main-shadow;
  z-index: 500;
}

.header__inner {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header__top {
  padding-left: $header-padding;
  padding-right: $header-padding;
  height: 40px;
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}

.header__top-title {
  color: #002469;
  font-weight: bold;
}

.header__top-right {
  display: flex;
  margin-right: 10px;
}

.header__top-button {
  width: 25px;
  height: 25px;
  background: $light-grey;
  margin-left: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $dark-grey;
  cursor: pointer;
  font-size: 14px;
}

.header__top-button:hover {
  background: $light-grey-hover;
}

.header__timeline-row {
  align-items: center;
  flex-grow: 1;
  padding-left: $header-padding;
  padding-right: $header-padding - 10;
  display: flex;
}

.header__stepper {
  width: $stepper-width;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.header__stepper-button {
  width: $stepper-button-size;
  height: $stepper-button-size;
  background: $light-grey;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $stepper-button-size / 2;
  font-size: 15px;
  color: $dark-grey;
  cursor: pointer;
}

.header__stepper-button:hover {
  background: $light-grey-hover;
}

.header__stepper-year {
  font-size: 30px;
  font-weight: bold;
}

.header__timeline {
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 2;
  overflow: hidden;
}

.timeline__slider {
  height: 40px;
  flex-grow: 1;
  margin-left: 30px;
}

.timeline__track {
  fill: lightgrey;
  cursor: pointer;
}

.timeline__handle {
  fill: $dark-grey;
  cursor: pointer;
  pointer-events: none;
}
.timeline__handle-line {
  pointer-events: none;
}

.timeline__slider-svg {
  background-color: lightgrey;
  border-radius: 5px;
  path {
    opacity: 0;
  }

  .tick {
    pointer-events: none;
    line {
      stroke: #999999;
    }

    text {
      font-size: 13px;
    }
  }
}

.timeline__axis {
  pointer-events: none;
}
