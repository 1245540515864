@import '../../config';

.sidebar {
  background: #eeeeee;
  display: flex;
  width: 320px;
  flex-direction: column;
  box-shadow: $main-shadow;
  flex-shrink: 0;
}

.sidebar__search-row {
  width: 100%;
  background: white;
  display: flex;
  height: 60px;
  align-items: center;

  color: $dark-grey;
  font-size: 15px;
  box-shadow: $main-shadow;
}

.sidebar__search-row--search {
  justify-content: space-between;
}

.sidebar__search-row--return {
  cursor: pointer;
}

.sidebar__search-row-left,
.sidebar__search-row-right {
  display: flex;
  height: 100%;
  align-items: center;
}

.sidebar__search-row-left {
  padding-left: 20px;
}

.sidebar__search-row-right {
  margin-right: 20px;
}

.sidebar__search-icon {
  margin-right: 10px;
}

.sidebar__search-icon--return {
  font-size: 30px;
  cursor: pointer;
}

.sidebar__text-input {
  border: none;
  height: 95%;
}

.sidebar__text-input:focus {
  outline: none;
}

.sidebar__search-row-divider {
  height: 26px;
  background: #cccccc;
  width: 1px;
  margin-right: 10px;
  margin-left: 10px;
}

.sidebar__search-back-icon {
  font-size: 30px;
  margin-left: 20px;
  margin-right: 6px;
}

.sidebar__search-back-text {
  font-style: italic;
}

.sidebar__inner {
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
  height: calc(100vh - 169px);
}

.sidebar__legend {
  margin-bottom: 10px;
}

.sidebar__block {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: $main-shadow;
  margin-top: 10px;
}

.sidebar__block-outer {
  margin-top: 20px;
}

.sidebar__block-title-row {
  display: flex;
  justify-content: space-between;
}

.sidebar__block-title-row-left {
  display: flex;
}

.sidebar__block-title-icon {
  margin-right: 8px;
}

.sidebar__block-toggle-button,
.sidebar__search-area-button {
  cursor: pointer;
}

.sidebar__search-area-button {
  width: 20px;
  height: 20px;
}

.sidebar__area-icon {
  fill: $dark-grey;
}

.sidebar__layers-block,
.sidebar__search-layer-group {
  margin-top: 20px;
}

.sidebar__layers-block--first,
.sidebar__search-layer-group--first {
  margin-top: 0px;
}

.sidebar__layers-title-left,
.sidebar__layers-title-right,
.sidebar__layers-title-row,
.sidebar__search-layer-title-row {
  text-transform: capitalize;
  display: flex;
}

.sidebar__layer-swatch {
  display: block;
  width: 38px;
  height: 31px;
  border-radius: 4px;
}

.sidebar__layers-title-left {
  font-size: 13px;
}

.sidebar__layers-title-right {
  color: $dark-grey;
  font-size: 24px;
  cursor: pointer;
}

.sidebar__layers-title-row,
.sidebar__search-layer-title-row {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.sidebar__layers-title {
  margin-left: 8px;
}

.sidebar__layer-row,
.sidebar__search-feature-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.sidebar__layer-button,
.sidebar__search-feature-button {
  min-height: 20px;
  width: 180px;
  display: flex;
  align-items: center;
  background: #eeeeee;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.sidebar__layer-button--highlighted,
.sidebar__layer-button:hover,
.sidebar__search-feature-button:hover,
.sidebar__search-feature-button--highlighted {
  background: rgb(221, 221, 221);
}

.sidebar__layer-button-inner,
.sidebar__search-feature-button-text {
  padding-left: 10px;
  padding-right: 10px;
}

.sidebar__choropleth-layer-row {
  margin-top: 10px;
}

.sidebar__choropleth-slider-labels {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
}

.sidebar__toggle-button {
  cursor: pointer;
}

.sidebar__filmstrip {
  display: flex;
  flex-wrap: wrap;
}

.sidebar__view-thumb {
  width: 70px;
  height: 70px;
  background: lightgrey;
  border-radius: 35px;
  margin-right: 7px;
  margin-bottom: 8px;
  cursor: pointer;
  background-size: cover;
}

.sidebar__view-thumb:nth-child(3n) {
  margin-right: 0px;
}

.sidebar__overlay-thumb {
  width: 70px;
  height: 70px;
  background: lightgrey;
  border-radius: 5px;
  margin-right: 7px;
  margin-bottom: 8px;
  cursor: pointer;
  background-size: cover;
}

.sidebar__overlay-thumb:nth-child(3n) {
  margin-right: 0px;
}

.sidebar__choropleth-slider {
  height: 25px;
}

.timeline__slider-svg {
  path {
    opacity: 0;
  }
}
